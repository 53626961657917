import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Header from '../components/header';
import HighLights from '../components/highlights';
import LocationList from '../components/locationList';

class Places extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: 'point'
    };
  }
  render() {
    const { intro } = this.props.pageContext;
    const { places } = this.props.data;

    return (
      <article className="page places">
        <Header
          title="Lugares de interese"
          back="/"
          intro={intro}
          path={this.props.location.pathname}
        />

        <h2>Destacados</h2>
        <HighLights
          highlights={places.edges.filter(p => p.node.frontmatter.highlight)}
        />
        <ul className="tabs">
          <li className={this.state.filter === 'point' ? 'selected' : ''}>
            <button onClick={() => this.setState({ filter: 'point' })}>
              Puntos
            </button>
          </li>
          <li className={this.state.filter === 'area' ? 'selected' : ''}>
            <button onClick={() => this.setState({ filter: 'area' })}>
              Áreas
            </button>
          </li>
        </ul>

        <LocationList
          locations={places.edges.filter(
            p => p.node.frontmatter.type === this.state.filter
          )}
        />
      </article>
    );
  }
}

export default Places;

export const query = graphql`
  query {
    places: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/lugares/" }
        frontmatter: { visible: { eq: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            type
            highlight
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
